<template>
  <div class="detail-wrap" @scroll.passive="getScroll($event)">
    <!-- 装载区域 -->
    <router-view></router-view>
    <!--快捷帮助-->
    <ul class="quick_helper" v-if="$route.name !== 'demonstrate' && $route.name !== 'download' && showBack">
      <li class="service" v-for="item in helperList"
          @mouseover="mouseOver(item)"
          @mouseleave="mouseLeave"
          @click="handleClick(item.id)"
          :key="item.id"
          :class="item.id">
        <img :src="item.flag ? item.focusUrl : item.normalUrl">
        <div class="phoneNumber" v-if="item.id == 'service' && phoneSelect">
          <img src="../../assets/image/handle/phone.png" alt="电话">
          <span>18658296496</span>
        </div>
      </li>
    </ul>
    <!-- 返回顶部 -->
    <div class="back-wrap" @click="back" v-if="$route.name !== 'demonstrate' && $route.name !== 'download' && showBack">
      <img src="../../assets/image/top.png" class="back" alt="">
    </div>
    <!--dialog-->
    <div class="dialog" v-if="tryOutVisible">
      <div class="dialog_content">
        <i class="closeBtn el-icon-circle-close" @click="handleClick('tryOut')"></i>
        <div class="dialog_title">马上报名免费注册试用轻变聚合产品，开启您的商业化之旅！</div>
        <div class="connection-wrap">
          <div class="inner-wrap">
            <p class="title text-shadow">留下您的联系方式，我们的变现顾问会尽快与您取得联系，为您开通免费试用账号！ </p>
            <!--<div class="line"></div>-->
            <div class="form-wrap">
              <div class="info-wraps contact-wrap">
                <div class="common-wrap" @click="focus('selectedTwo')" :class="{'selected': select.selectedTwo}">
                  <el-input placeholder="请输入您的姓名" v-model.trim="formData.userName" clearable @blur.native.capture="userNameBlur" @focus="focus('selectedTwo')"></el-input>
                </div>
                <div class="common-wrap" @click="focus('selectedOne')" :class="{'selected': select.selectedOne}">
                  <el-input placeholder="请输入您的公司名称" v-model="formData.companName" clearable @focus="focus('selectedOne')"></el-input>
                </div>
              </div>
              <div class="info-wraps contact-wrap">
                <div class="common-wrap" @click="focus('selectedThree')" :class="{'selected': select.selectedThree}">
                  <el-input placeholder="请输入您的手机号码" v-model.number="formData.phone" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" clearable @blur.native.capture="phoneBlur" @focus="focus('selectedThree')"></el-input>
                </div>
                <div class="common-wrap" @click="focus('selectedSix')" :class="{'selected': select.selectedSix}">
                  <el-input placeholder="请输入您的APP名称" v-model="formData.appName" clearable @focus="focus('selectedSix')"></el-input>
                </div>
                <!--<div class="common-wrap" @click="focus('selectedFive')" :class="{'selected': select.selectedFive}">-->
                  <!--<el-input placeholder="请输入您的电子邮箱" v-model="formData.email" clearable @focus="focus('selectedFive')"></el-input>-->
                <!--</div>-->
              </div>
              <div class="info-wraps contact-wrap">
                <div class="common-wrap" @click="focus('selectedFour')" :class="{'selected': select.selectedFour}">
                  <el-input placeholder="请输入6位验证码" v-model.trim="formData.messageCode" maxlength="6" oninput="value=value.replace(/[^\d]/g,'')" clearable style="width: 62% !important;float: left" @focus="focus('selectedFour')"></el-input>
                  <div class="send" @click.stop="sendMessage" :class="{'disabled': disabled == true}">{{ buttonMsg }}</div>
                </div>
              </div>
              <!-- 立即登录 -->
              <div class="login" @click="onSubmit">提交信息</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collectUser, getMessage } from "@/api/api";
export default {
  name: "home",
  data() {
    return {
      backSeconds : 100,
      phoneSelect: false,
      showBack: this.$route.path !== '/home/index', // 是否展示返回顶部以及侧边提示
      // 固定右侧列表
      helperList: [
        {
          id: 'askAbout',
          flag: false,
          normalUrl: require("../../assets/image/handle/askAbout.png"),
          focusUrl: require("../../assets/image/handle/askAboutActive.png")
        },
        {
          id: 'service',
          flag: false,
          normalUrl: require("../../assets/image/handle/service.png"),
          focusUrl: require("../../assets/image/handle/serviceActive.png")
        },
        {
          id: 'showHow',
          flag: false,
          normalUrl: require("../../assets/image/handle/showHow.png"),
          focusUrl: require("../../assets/image/handle/showHowActive.png")
        },
        {
          id: 'tryOut',
          flag: false,
          normalUrl: require("../../assets/image/handle/tryOut.png"),
          focusUrl: require("../../assets/image/handle/tryOutActive.png")
        }
      ],
      // 弹层是否展示
      tryOutVisible: false,
      // 注册使用弹层表单是否被触发焦点
      select: {
        selectedTwo: false,
        selectedOne: false,
        selectedThree: false,
        selectedFour: false,
        selectedFive: false,
        selectedSix: false,
      },
      buttonMsg: '发送验证码',// 发送按钮文案
      disabled: true,       // 发送短信按钮是否禁用
      timeDown: 60,         // 倒计时按钮
      userNameFill: false,          // 姓名是否填充
      phoneFill: false,             // 手机号是否填充
      // 表单数据
      formData: {
        userName: '',
        companName: '',
        phone: '',
        appName: '',
        // email: '',
        messageCode: ''
      }
    }
  },
  watch: {
    '$route.path'(path) { this.showBack = path !== '/home/index' }
  },
  methods: {
    // 返回顶部
    back() {
      document.documentElement.scrollTop = 0;
      window.pageYOffset = 0;
		  document.body.scrollTop = 0;
    },
    // 鼠标移入
    mouseOver(item) {
      if (item.id !== 'service') {
        this.phoneSelect = false;
      }
      item.flag = true;
      for (let i in this.helperList) {
        if (this.helperList[i].id !== item.id) {
          this.helperList[i].flag = false;
        }
      }
    },
    // 鼠标移出
    mouseLeave() {
      for(let i in this.helperList) {
        this.helperList[i].flag = false;
      }
    },
    // 鼠标点击实现不同操作
    handleClick(id) {
      // 在线咨询
      if(id == 'askAbout') {
        // window.open('http://p.qiao.baidu.com/cps/chat?siteId=15926720&userId=31420752&siteToken=847e66b9805066a3b369c02889def33b&cp=http%3A%2F%2Fad-scope.com.cn&cr=http%3A%2F%2Fad-scope.com.cn&cw=%E5%AE%98%E7%BD%91%E5%92%A8%E8%AF%A2%E5%85%A5%E5%8F%A3', "customerService");
        window.open('http://p.qiao.baidu.com/cps/chat?siteId=15926720&userId=31420752&siteToken=847e66b9805066a3b369c02889def33b', "customerService");
      }
      // 客服专线
      else if(id == 'service') {
        this.phoneSelect = !this.phoneSelect;
      }
      // 预约演示
      else if(id == 'showHow') {
        window.open(window.location.origin + '#/home/demonstrate', 'order');
      }
      // 注册适用
      else if(id == 'tryOut') {
        this.formData.companName = '';
        this.formData.userName = '';
        this.formData.phone = '';
        this.formData.messageCode = '';
        this.formData.appName = '';
        // this.formData.email = '';
        this.tryOutVisible = !this.tryOutVisible;
      }
    },
    // 注册试用弹层input触发焦点
    focus(name) {
      this.select[name] =true;
      for(let i in this.select) {
        if(i !== name) {
          this.select[i] =false;
        }
      }
    },
    // 姓名输入框失焦点事件
    userNameBlur() {
      if(this.formData.userName.length > 0) {
        this.userNameFill = true;
      }else {
        this.userNameFill = false;
      }
      if(this.phoneFill == true && this.userNameFill == true) {
        this.disabled = false;
      }else {
        this.disabled = true;
      }
    },
    // 手机号输入框失焦点事件
    phoneBlur() {
      let aa = this.formData.phone+'';
      if(aa.length > 0) {
        this.phoneFill = true;
      }else {
        this.phoneFill = false;
      }
      if(this.phoneFill == true && this.userNameFill == true) {
        this.disabled = false;
      }else {
        this.disabled = true;
      }
    },
    sendMessage() {
      this.utils.sendMessage(this,this.formData.phone,this.formData.userName)
    },
    // 提交接口
    async onSubmit() {
      if (this.formData.userName == "") {
        this.$message({
          message: '请输入您的姓名',
          type: 'warning'
        });
        return false;
      }
      let phone_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
      if (this.formData.phone == "") {
        this.$message({
          message: '请输入您的手机号',
          type: 'warning'
        });
        return false;
      }
      if (!phone_reg.test(this.formData.phone)) {
        this.$message({
          message: '请输入正确格式的手机号码',
          type: 'warning'
        });
        return false;
      }
      // let email_reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
      // if (this.formData.email !== '' && !email_reg.test(this.formData.email)) {
      //   this.$message({
      //     message: '请输入正确格式的电子邮箱',
      //     type: 'warning'
      //   });
      //   return false;
      // }

      if (this.formData.appName == "") {
        this.$message({
          message: '请输入APP名称',
          type: 'warning'
        });
        return false;
      }
      if (this.formData.messageCode == "") {
        this.$message({
          message: '请输入收到的验证码',
          type: 'warning'
        });
        return false;
      }
      let datas = {
        deptName: this.formData.companName,
        userName: this.formData.userName,
        phonenumber: this.formData.phone+'',
        verifyCode: this.formData.messageCode,
        // email: this.formData.email,
        appName: this.formData.appName,
        userType: "1",
      };
      const res = await collectUser(datas);
      if(res.code == 200) {
        this.$message({
          message: '信息提交成功',
          type: 'success'
        });
        this.formData.companName = '';
        this.formData.userName = '';
        this.formData.phone = '';
        this.formData.messageCode = '';
        this.formData.appName = '';
        // this.formData.email = '';
        this.timeDown = 0;
        this.tryOutVisible = false;
      }else {
        let message= res.msg;
        this.$message({
          message: `${message}`,
          type: 'error'
        });
      }
    },
  },

  mounted() {
    setTimeout(function(){$('.qiao-icon-close').append("<span id='bdclick'></span>");$('#bdclick').click();},1000);
    // 当滚动高度超过800  展示返回顶部以及侧边提示
    window.onscroll = () => {
      if (this.$route.path === '/home/index') {
          this.showBack = document.documentElement.scrollTop > 800;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.detail-wrap {
  min-width: 1400px;
}
.back-wrap {
  position: fixed;
  bottom: 190px;
  right: 100px;
  width: 80px;
  height: 80px;
  z-index: 9;
  .back {
    display: block;
    width: 80px;
    height: 80px;
    cursor: pointer;
  }
}
.quick_helper{
  right: 0;
  top: 50%;
  z-index: 100;
  width: 80px;
  padding: 10px;
  position: fixed;
  margin-top: -160px;
  border-radius: 5px;
  background: rgba(78,136,255, 0.25);
  box-shadow: -1px 3px 10px rgba(11,2,5, 0.25);
  .service{
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: -1px 3px 10px rgba(11,2,5, 0.25);
  }
  .service{
    position: relative;
    .phoneNumber{
      top: 5px;
      left: -212px;
      width: 206px;
      height: 50px;
      padding: 0 18px;
      line-height: 50px;
      position: absolute;
      border-radius: 5px;
      box-shadow: -6px 1px 15px rgba(11,2,5, 0.25);
      background: url("../../assets/image/handle/dialogBox.png") no-repeat;
      img{
        display: inline-block;
        float: left;
        margin-top: 13px;
      }
      span{
        font-size: 18px;
        padding-left: 10px;
        font-weight: bolder;
      }
    }
  }
  .service:last-child{
    margin-bottom: 0;
  }
}
.dialog{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  .dialog_content{
    top: 50%;
    left: 50%;
    width: 840px;
    height: 560px;
    background: #fff;
    position: absolute;
    margin-top: -280px;
    margin-left: -420px;
    box-shadow: 0px 5px 10px 0px rgba(7, 0, 147, 0.15);
    .closeBtn{
      position: absolute;
      right: -60px;
      top: 10px;
      font-size: 40px;
      color: #fff;
      cursor: pointer;
    }
    .dialog_title{
      height: 100px;
      color: #ffffff;
      font-size: 24px;
      text-align: center;
      line-height: 100px;
      font-weight: bolder;
      background: url("../../assets/image/index/titleBG.png") no-repeat center;
      background-size: 100% 100%;
    }
    .connection-wrap {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      .inner-wrap {
        width: 100%;
        text-align: center;
        .title{
          height: 100px;
          font-size: 18px;
          line-height: 100px;
          font-weight: bolder;
        }
        .form-wrap {
          width: 100%;
          padding: 0 40px;
          .info-wraps {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .common-wrap {
              width: 360px;
              height: 53px;
              line-height: 53px;
              margin-bottom: 30px;
              border: 1px solid #E5E5E5;
              border-radius: 10px;
              box-sizing: border-box;
              position: relative;
              .send {
                display: inline-block;
                width: 100px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: #493DD1;
                border-radius: 6px;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 10px;
              }
              .send.disabled {
                background-color: #f0f0f0;
                color: #ccc;
              }
              .login-icon {
                display: inline-block;
                width: 28px;
                height: 28px;
                vertical-align: middle;
              }
              .code-icon {
                display: inline-block;
                width: 70px;
                height: 35px;
                vertical-align: middle;
                cursor: pointer;
              }
            }
            .common-wrap.selected {
              border: 1px solid #493DD1;
            }
            .el-input /deep/ .el-input__inner {
              border: 0 none !important;
              color: #493DD1 !important;
            }
          }
          .login {
            width: 400px;
            height: 55px;
            line-height: 55px;
            background: linear-gradient(-10deg, #A634E7, #493DD1);
            box-shadow: 0px 5px 10px 0px rgba(7, 0, 147, 0.15);
            border-radius: 30px;
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            color: #fff;
            cursor: pointer;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
