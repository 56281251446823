<template>
  <div class="header-wrap" :class="{ 'graybg':  active }">

    <div class="new-main-container flex" >
      <!-- 点击logo回首页 -->
      <div class="logo-wrap" :class="$route.name !== 'download' ? 'pointer' : ''" @click="handleIndex">
        <img src="../assets/image/logo.png" class="logo" alt="">
      </div>
      <nav-menu :activeindex="activeindex"></nav-menu>
      <div class="register-wrap flex"  @click="register" v-if="$route.name !== 'download'">
        <span class="register">解读会员权益</span>
      </div>
      <div class="login-wrap flex" v-if="$route.name !== 'download'">
        <div class="register" @click="goRegister">注 册</div>
        <div class="login"  @click="goLogin">登 录</div>
      </div>
    </div>

  </div>
</template>
<script>
import NavMenu from './nav-menu';
export default {
  components: { NavMenu },
  props:{
    activeindex: { type: Number, default: 0 }
  },
  data() {
    return {
      active: false,        // 是否添加头部导航背景颜色
      isHomePage: null,        // 是否为首页
    }
  },
  methods: {
    // 监听页面滚动条
    handleScroll() {
      // header在水平滚动条滚动时可以跟随滚动条移动
      let sl = -Math.max(document.body.scrollLeft, document.documentElement.scrollLeft);
      document.getElementsByClassName('header-wrap')[0].style.left = sl + 'px';
      // 是否添加header背景颜色
      this.scroll = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      if(this.scroll > 80 ) {
        this.active = true;
      }else {
        this.active = false;
      }
    },

    // 跳转到首页并且定位到表单位置
    register() {
      if(this.isHomePage) {
        document.documentElement.scrollTop = 3780;
        window.pageYOffset = 3780;
        document.body.scrollTop = 3780;
      }else {
        this.$router.push({
          // path: `/home/index?jump=to`
          name: 'index',
          params: {
            jumpTo: true
          }
        });
      }
    },

    // 点击跳转到注册页
    goRegister() {
      window.open(process.env.VUE_APP_HREF_AD_SCOPE + '#/register', "register");
    },

    // 点击跳转到登录页
    goLogin() {
      window.open(process.env.VUE_APP_HREF_AD_SCOPE + '#/login', "login");
    },

    // 点击logo跳转首页
    handleIndex() {
      if(this.$route.name !== 'download') this.$router.push({ name: 'index' })
    }

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if (window.location.hash.includes('index')) {
      this.isHomePage = true;
    } else {
      this.isHomePage = false;
    }
  },
};



</script>
<style lang="scss" scoped>
/* .graybg { */
  /* background:rgba(32,23,36,0.9) !important; */
  /* background: linear-gradient(to bottom, rgba(229, 242, 255, 0.95), rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)) !important; */
/* } */
.header-wrap {
  width: 100%;
  min-width: 1400px;
  height: 80px;
  line-height: 80px;
  /* background: transparent; */
  background: linear-gradient(to bottom, rgb(229, 242, 255), rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.65));
  color: #E6E6E6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .new-main-container {
    margin: 0 40px;
  }

  /* logo部分 */
  .logo-wrap {
    display: flex;
    align-items: center;
    height: 80px;
    .logo {
      width: 220px;
      // height: 50px;
    }
  }
  /* 登录部分 */
  .login-wrap {
    .login, .register {
      display: inline-block;
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
    .login {
      background: $theme-color;
    }
    .register {
      margin-right: 10px;
      background: #178bf0;
    }
  }
  /*解读会员权益*/
  .register-wrap{
    margin-right: 20px;
    cursor: pointer;
    width: 160px;
    height: 30px;
    text-align: center;
    color: #fff;
    .register{
      display: inline-block;
      margin: auto;
      font-size: 14px;
      padding-left: 14px;
      line-height: 30px;
      color: #f88c34;
      background: url("../assets/image/vip.png") no-repeat;
      background-position: left top 1px;
      padding-left: 36px;
      letter-spacing: 1px;
    }
  }
  .register-wrap:hover{
    background: rgba(255,208,121,0.35);
    border-radius: 25px;
  }
}
</style>
