import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import './style/common.css'
import utils from '@/utils/utils'
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  Message,
  Form,
  Select,
  Input,
  Button,
  FormItem,
  Option,
  Row,
  Icon,
  Popover,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
Vue.use(vueSwiper)
Vue.use(Form)
Vue.use(Select)
Vue.use(Input)
Vue.use(Button)
Vue.use(FormItem)
Vue.use(Option)
Vue.use(Row)
Vue.use(Icon)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Pagination)
Vue.config.productionTip = false
Vue.prototype.utils = utils
import $ from 'jquery'
Vue.prototype.$ = $;

// 引入公共字体scss样式
import '@/style/skin.scss'

// 局部引入element.ui的message提示信息
Vue.prototype.$message = Message

window.Vue = Vue;


// 百度统计代码
const _hmt = _hmt || [];
window._hmt = _hmt;
if(window.location.href.indexOf('download') == -1) {
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?847e66b9805066a3b369c02889def33b";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}




// 路由钩子
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }

  // 百度统计
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next()
});


// 动态获取屏幕宽度,做移动端自适配;
(function (doc, win) {
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      if (clientWidth >= 375 && clientWidth <= 750) {
        docEl.style.fontSize = 16 * (clientWidth / 750) + 'px';
      }
    };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window)


new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
